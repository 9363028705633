<template>
  <div class="Solution" v-lazy:background-image="bg_solution">
    <div class="Solution-center">
      <BlockTitle
        :cnName="titleConfig.cnName"
        :enName="titleConfig.enName"
      />
      <div class="Solution-desc">
        <p>企业注册一站式的申报清单采集，数据通过EDI直接对接海关、税务等平台，集货仓、海外仓、海运/铁/空/陆运输通道全产业链服务。</p>
        <p>支持企业主体申报出口，获得贸易数据，作为收汇时的出口贸易背景，实现公账收款；</p>
        <p>以此进行对应税务申报，实现企业从通关到境内财税全流程合规。</p>
      </div>
      <div class="Solution-container">
        <div class="Solution-container-left">
          <template v-for="(iconItem, index) in solutionIcons">
            <div :key="index" class="solution_icon_item">
              <div class="icon"><img v-lazy="iconItem.icon" alt=""></div>
              <div class="title">{{ iconItem.title }}</div>
            </div>
          </template>
        </div>
        <div class="Solution-container-right"><img v-lazy="solution_illustration" alt=""></div>
      </div>
    </div>
  </div>
</template>

<script>
  import BlockTitle from '@/components/BlockTitle.vue';
  // import bg_solution from '@/assets/images/home/bg_solution.jpg';
  import solution_illustration from '@/assets/images/home/solution_illustration.jpg';
  import solution_icon_1 from '@/assets/images/home/solution_icon_1.png';
  import solution_icon_2 from '@/assets/images/home/solution_icon_2.png';
  import solution_icon_3 from '@/assets/images/home/solution_icon_3.png';
  import solution_icon_4 from '@/assets/images/home/solution_icon_4.png';
  import solution_icon_5 from '@/assets/images/home/solution_icon_5.png';
  import solution_icon_6 from '@/assets/images/home/solution_icon_6.png';
  export default {
    name: 'Solution',
    components: {
      BlockTitle,
    },
    data() {
      return {
        titleConfig: {
          cnName: '一站式跨境电商进出口解决方案',
          enName: 'CROSS BORDER E-COMMERC SOLUTION',
        },
        bg_solution: `${ this.$ossUrl }home/bg_solution.jpg${this.$ossHash}`,
        // solution_illustration: `${ this.$ossUrl }home/solution_illustration.jpg${this.$ossHash}`,
        solution_illustration,
        solutionIcons: [
          {
            icon: solution_icon_1,
            title: '一站式清单采集'
          },
          {
            icon: solution_icon_2,
            title: 'EDI数据对接'
          },
          {
            icon: solution_icon_3,
            title: '仓储物流一体化'
          },
          {
            icon: solution_icon_4,
            title: '通关合规'
          },
          {
            icon: solution_icon_5,
            title: '收汇合规'
          },
          {
            icon: solution_icon_6,
            title: '财税合规'
          },
        ]
      }
    },
  }
</script>

<style lang="scss" scoped>
.Solution{
  width: 100%;
  height: 940px;
  margin-top: 100px;
  // background: url('../../assets/images/home/bg_solution.jpg') center center no-repeat;
  background-position: center center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  &-center{
    width: 1510px;
    padding: 100px 0;
    margin-left: calc((100% - 1510px) * 0.60976);
    margin-right: calc((100% - 1510px) * 0.39024);
    box-sizing: border-box;
  }
  &-desc{
    width: 1000px;
    margin: 20px auto 40px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    text-align: center;
    p{
      color: #5F5F6A;
      line-height: 22px;
    }
  }
  &-container{
    display: flex;
    justify-content: space-between;
    &-left{
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      padding: 50px 0;
      .solution_icon_item{
        width: 33.33%;
        margin-bottom: 100px;
        .icon{
          width: 64px;
          height: 64px;
          margin: 0 auto;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .title{
          margin-top: 15px;
          text-align: center;
          color: #33333B;
          // font-weight: 700;
          font-size: 24px;
        }
      }
    }
    &-right{
      width: 660px;
      height: 420px;
      margin-left: 100px;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
@media screen and (min-width: 1366px) and (max-width: 1510px) {
  .Solution {
    &-center{
      width: 1366px;
      margin-left: calc((100% - 1366px) * 0.60976);
      margin-right: calc((100% - 1366px) * 0.39024);
    }
  }
}
@media screen and (max-width: 1366px) {
  .Solution {
    &-center{
      width: 1200px;
      margin-left: calc((100% - 1200px) * 0.60976);
      margin-right: calc((100% - 1200px) * 0.39024);
    }
    &-container{
      &-left{
        .solution_icon_item{
          .title{
            font-size: 18px;
          }
        }
      }
    }
  }
}
</style>