<template>
  <div class="Banners">
    <slot></slot>
    <div id="Banners_swiper" class="Banners-swiper">
      <div class="swiper-wrapper">
        <template v-for="(banner, index) in bannerList">
          <div :key="`banner_${index}`" class="swiper-slide">
            <a
              :href="banner.adLink || `javascript:void(0)`"
              :target="banner.adLink ? `_blank` : '_self'"
              rel="noopener noreferrer"
              style="display: block;"
            >
              <img v-lazy="banner.image" alt="">
            </a>
            <!-- <div class="Banners-content">
              <div class="Banners-content-container">
                <div class="title">做产业聚集领跑者</div>
                <div class="desc">平台系统为跨境电商新业态、新产业提供“阳光关务、仓储物流、结汇退税、财税合规”的一站式数字化综合服务为国内集货仓、关务服务、海运通道(空运通道)、海外仓等跨境电商9610、9710、9810、1210进出口提供供应链一揽子解决方案。</div>
              </div>
            </div> -->
          </div>
        </template>
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper/swiper-bundle";
import "swiper/swiper-bundle.css";
// import banner_1 from '@/assets/images/home/banner_1.jpg';
export default {
  name: "Banners",
  components: {
  },
  data() {
    return {
      bannerList: []
    };
  },
  mounted () {
    this.loadSwiper();
  },
  methods: {
    async loadSwiper() {
      const bannerSwiper = new Swiper("#Banners_swiper", {

        // 如果需要分页器
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        // on: {
        //   slideChangeTransitionEnd: function(){
        //     this.slides.transition(this.params.autoplay.delay+this.params.speed).transform('translate3d(-60px, 0, 0)');
        //   },
        //   slideChangeTransitionStart: function(){
        //     this.slides.transition(this.params.speed).transform('translate3d(0, 0, 0)');
        //   },
        // },

        // // 如果需要前进后退按钮
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },

        autoplay: {
          delay: 3600,
          // stopOnLastSlide: false,
          // disableOnInteraction: false,
        },
        // loop: true, // 循环模式选项

        // 如果需要滚动条
        // scrollbar: {
        //   el: '.swiper-scrollbar',
        // },
      });
      bannerSwiper.el.onmouseover = function(){
        bannerSwiper.autoplay.stop();
      }
      bannerSwiper.el.onmouseout = function(){
        bannerSwiper.autoplay.start();
      }
      const res = await this.$request.get(`/ad/data/home_banner`);
      this.bannerList = res.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.Banners {
  width: 100%;
  max-height: 700px;
  position: relative;
  &-swiper{
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    .swiper-wrapper{
      .swiper-slide{
        position: relative;
      }
    }
    --swiper-theme-color: #3484FB;
    // --swiper-pagination-color: #00ff33;
    ::v-deep(.swiper-pagination){
      .swiper-pagination-bullet{
        width: 40px;
        height: 8px;
        border-radius: 2px;
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
  &-content {
    position: absolute;
    left: 0;
    top: 158px;
    width: 100%;
    &-container {
      width: 1050px;
      margin: 0 auto;
      .title {
        margin-bottom: 36px;
        font-size: 58px;
        color: #ffffff;
      }
      .desc {
        width: 820px;
        line-height: 28px;
        font-size: 20px;
        color: #ffffff;
      }
    }
  }
}
</style>