<template>
  <div class="CustomsService">
    <template v-for="(customsItem, index) in lists">
      <div :key="index" class="CustomsService-item">
        <div class="img"><img v-lazy="customsItem.image" alt=""></div>
        <div class="title">{{ customsItem.adName }}</div>
        <div class="desc">{{ customsItem.remark }}</div>
      </div>
    </template>
  </div>
</template>

<script>
  // import customs_service_1 from '@/assets/images/home/customs_service_1.jpg';
  // import customs_service_2 from '@/assets/images/home/customs_service_2.jpg';
  // import customs_service_3 from '@/assets/images/home/customs_service_3.jpg';
  // import customs_service_4 from '@/assets/images/home/customs_service_4.jpg';
  export default {
    name: 'Business',
    data() {
      return {
        lists: [
          {
            url: `${ this.$ossUrl }home/customs_service_1.jpg${this.$ossHash}`,
            title: '阳光关务',
            desc: '健全工作机制，规范工作流程，高效通关服务，海关流程透明，企业明明白白通关。'
          },
          {
            url: `${ this.$ossUrl }home/customs_service_2.jpg${this.$ossHash}`,
            title: '仓储物流',
            desc: '集货仓、关务、海运/空运通道、海外仓、仓储物流一体化发展，提高订单交付能力。'
          },
          {
            url: `${ this.$ossUrl }home/customs_service_3.jpg${this.$ossHash}`,
            title: '海外仓',
            desc: '提供清关、入库质检、接受订单、商品分拣、配送等服务，降低物流成本，节约时间，提升客户满意度和产品曝光率。'
          },
          {
            url: `${ this.$ossUrl }home/customs_service_4.jpg${this.$ossHash}`,
            title: '结汇退税',
            desc: '一站式清单核放、汇总申报、清单归并报关、数据自动上传相关部门、结汇退税。'
          }
        ]
      }
    },
    mounted () {
      this.getList();
    },
    methods: {
      async getList() {
        const res = await this.$request.get(`/ad/data/home_business`);
        this.lists = res.data;
      }
    },
  }
</script>

<style lang="scss" scoped>
.CustomsService{
  display: flex;
  width: 1520px;
  height: 326px;
  margin: 100px auto;
  &-item{
    width: calc(25% - 26px + 6px);
    margin-left: 26px;
    &:first-child{
      margin-left: 0;
    }
    .img{
      width: 100%;
      height: 57.669%;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .title{
      margin: 28px 24px 14px;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 700;
      color: #33333B;
    }
    .desc{
      line-height: 22px;
      margin: 0 24px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #5F5F6A;
    }
  }
}
@media screen and (min-width: 1366px) and (max-width: 1510px) {
  .CustomsService {
    width: 1366px;
    height: 293px;
    .title{
      font-size: 20px;
    }
    .desc{
      line-height: 18px;
      font-size: 14px;
    }
  }
}
@media screen and (max-width: 1366px) {
  .CustomsService {
    width: 1200px;
    height: 258px;
    .title{
      font-size: 18px;
    }
    .desc{
      line-height: 16px;
      font-size: 12px;
    }
  }
}
</style>