<template>
  <div class="News">
    <BlockTitle
      :cnName="cnName"
    />
    <div class="News-container" v-if="lists.length">
      <template v-for="(news, index) in lists">
        <div :key="index" class="News-container-item">
          <div class="img"><img v-lazy="news.image" alt=""></div>
          <div class="News-container-item-content">
            <div class="News-container-item-content-left">
              <img src="@/assets/images/home/news_time_tag.png" alt="">
              <div class="value">
                <div>{{ formatDate(news.createDate).month }}</div>
                <div>{{ formatDate(news.createDate).day }}</div>
              </div>
            </div>
            <div class="News-container-item-content-right">
              <div class="title">{{ news.title }}</div>
              <div class="date">{{ formatDate(news.createDate).format }}</div>
              <div class="desc">{{ news.description }}</div>
            </div>
          </div> 
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import BlockTitle from '@/components/BlockTitle.vue';
  // import news_illustration_1 from '@/assets/images/home/news_illustration_1.jpg';
  // import news_illustration_2 from '@/assets/images/home/news_illustration_2.jpg';
  // import news_illustration_3 from '@/assets/images/home/news_illustration_3.jpg';
  export default {
    name: 'News',
    components: {
      BlockTitle,
    },
    data() {
      return {
        cnName: '新闻动态',
        lists: []
      }
    },
    mounted () {
      this.getList();
    },
    methods: {
      async getList() {
        const res = await this.$request.get(`/article/recommend-stat`,{ params: { page: 1, size: 4 } } );
        const { articles } = res.data;
        if(articles && articles.length) {
          this.lists = articles;
        }
      },
      formatDate(date) {
        const newDate = new Date(date);
        let year = newDate.getFullYear();
        let month = `${newDate.getMonth() + 1}`.padStart(2, '0');
        let day = `${newDate.getDate()}`.padStart(2, '0');
        return {
          year,
          month,
          day,
          format: `${year}-${month}-${day}`
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.News{
  margin-top: 100px;
  &-title{
    margin-bottom: 30px;
    text-align: center;
    font-size: 42px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 700;
    color: #171717;
  }
  &-container{
    display: flex;
    width: 1520px;
    height: 520px;
    margin: 60px auto 0;
    &-item{
      width: calc(33.33333% - 25px + 8px);
      margin-left: 25px;
      box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.1);
      &:first-child{
        margin-left: 0;
      }
      .img{
        width: 100%;
        height: 61.539%;
        img{
          width: 100%;
          height: 100%;
        }
      }
      &-content{
        display: flex;
        margin-top: 16px;
        padding-right: 24px;
        padding-bottom: 24px;
        &-left{
          width: 54px;
          height: 92px;
          margin-right: 18px;
          position: relative;
          img{
            width: 100%;
            height: 100%;
          }
          .value{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            color: #fff;
            text-align: center;
            div:first-child{
              margin: 4px 0;
              font-size: 24px;
              color: #fff;
            }
            div:last-child{
              font-size: 16px;
              color: #fff;
            }
          }
        }
        &-right{
          flex: 1;
          .title{
            font-size: 24px;
            font-weight: 700;
            color: #33333B;
            text-overflow :ellipsis;
            overflow : hidden;
            text-overflow: -o-ellipsis-lastline;
            display: -webkit-box;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;	
          }
          .date{
            margin: 8px 0;
            font-size: 16px;
            color: #909097;
          }
          .desc{
            line-height: 22px;
            font-size: 16px;
            color: #5F5F6A;
            text-overflow :ellipsis;
            overflow : hidden;
            text-overflow: -o-ellipsis-lastline;
            display: -webkit-box;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;	
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1366px) and (max-width: 1510px) {
  .News {
    &-container{
      width: 1366px;
      height: 467.316px;
      &-item{
        &-content{
          &-right{
            .title{
              font-size: 22px;
            }
            .date{
              font-size: 14px;
            }
            .desc{
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1366px) {
  .News {
    &-container{
      width: 1200px;
      height: 420.526px;
      &-item{
        &-content{
          &-right{
            .title{
              font-size: 18px;
            }
            .date{
              font-size: 12px;
            }
            .desc{
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>