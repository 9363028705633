<template>
  <div class="home">
    <Banners>
      <HeaderNav :navIndex="0" />
    </Banners>
    <About :isHide="true" />
    <CustomsService />
    <Business />
    <News />
    <Solution />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderNav from '@/components/HeaderNav.vue';
import Banners from './Banners.vue';
import About from './About.vue';
import CustomsService from './CustomsService.vue';
import Business from './Business.vue';
import News from './News.vue';
import Solution from './Solution.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'Home',
  components: {
    HeaderNav,
    Banners,
    About,
    CustomsService,
    Business,
    News,
    Solution,
    Footer
  },
}
</script>
<style lang="scss" scoped>
.home{
}
</style>
