<template>
  <div class="content_center Business" v-lazy:background-image="bg_business">
    <div class="Business-container" @mouseleave="handleMouseLeave">
      <template v-for="(business, index) in lists">
        <div
          :key="index"
          :class="[
            'Business-container-item',
            mouseEnterIndex === index ? 'active' : '',
          ]"
          @mouseenter="handleMouseEnter(index)"
        >
          <div class="img"><img v-lazy="mouseEnterIndex === index ? business.activeUrl : business.url" alt="" /></div>
          <div class="Business-container-item-container">
            <div class="title">{{ business.title }}</div>
            <div class="icon"><img v-lazy="business.icon" alt="" /></div>
            <template v-if="mouseEnterIndex === index">
              <div v-if="business.quickAccess">
                <template v-for="(item, index) in business.quickAccess">
                  <span v-if="index" :key="item.name" class="desc"> | </span>
                  <router-link
                    v-if="item.routerLink"
                    :key="item.name"
                    :to="item.routerLink"
                    class="desc"
                  >{{ item.name }}</router-link>
                  <span
                    v-else
                    :key="item.name"
                    class="desc"
                  >{{ item.name }}</span>
                </template>
              </div>
              <div v-if="business.desc" class="desc">{{ business.desc }}</div>
              <router-link v-if="business.routerLink" :to="business.routerLink">查看详情 ></router-link>
              <div v-if="business.linkUrl" class="detail_btn" @click="handleOpenPage(business.linkUrl)">
                查看详情 >
              </div>
            </template>
          </div>
        </div>
      </template>
    </div>
    <div class="Business-control">
      <div class="Business-control-icon"></div>
    </div>
  </div>
</template>

<script>
// import bg_business from "@/assets/images/home/bg_business.jpg";
// import business_illustration_1 from "@/assets/images/home/business_illustration_1.jpg";
// import business_illustration_2 from "@/assets/images/home/business_illustration_2.jpg";
// import business_illustration_3 from "@/assets/images/home/business_illustration_3.jpg";
// import business_illustration_4 from "@/assets/images/home/business_illustration_4.jpg";
// import business_illustration_active_1 from "@/assets/images/home/business_illustration_active_1.jpg";
// import business_illustration_active_2 from "@/assets/images/home/business_illustration_active_2.jpg";
// import business_illustration_active_3 from "@/assets/images/home/business_illustration_active_3.jpg";
// import business_illustration_active_4 from "@/assets/images/home/business_illustration_active_4.jpg";
import business_icon_1 from '@/assets/images/home/business_icon_1.png';
import business_icon_2 from '@/assets/images/home/business_icon_2.png';
import business_icon_3 from '@/assets/images/home/business_icon_3.png';
import business_icon_4 from '@/assets/images/home/business_icon_4.png';
import { throttle } from '@/utils';

export default {
  name: "Business",
  data() {
    return {
      bg_business: `${ this.$ossUrl }home/bg_business.jpg${this.$ossHash}`,
      lists: [
        {
          url: `${ this.$ossUrl }home/business_illustration_2.jpg${this.$ossHash}`,
          activeUrl: `${ this.$ossUrl }home/business_illustration_active_2.jpg${this.$ossHash}`,
          title: "进出口申报",
          linkUrl: this.$customsUrl,
          icon: business_icon_2,
          desc: "9610/9710/9810出口申报管理｜1210进口申报管理",
        },
        {
          url: `${ this.$ossUrl }home/business_illustration_4.jpg${this.$ossHash}`,
          activeUrl: `${ this.$ossUrl }home/business_illustration_active_4.jpg${this.$ossHash}`,
          title: "业务查询",
          linkUrl: this.$customsUrl,
          icon: business_icon_4,
          // desc: "报关查询 ｜ 流程查询 ｜回执信息查询 ｜ 历史信息查询 ",
          quickAccess: [
            { name: '报关查询', },
            { name: '流程查询', },
            { name: '回执信息查询', },
            { name: '历史信息查询', },
            { name: '物流信息查询', routerLink: '/logistics' },
          ]
        },
        {
          url: `${ this.$ossUrl }home/business_illustration_1.jpg${this.$ossHash}`,
          activeUrl: `${ this.$ossUrl }home/business_illustration_active_1.jpg${this.$ossHash}`,
          title: "资质办理",
          icon: business_icon_1,
          desc: "电子口岸卡办理丨企业资质备案丨数据传输ID申请丨海外仓备案",
        },
        {
          url: `${ this.$ossUrl }home/business_illustration_3.jpg${this.$ossHash}`,
          activeUrl: `${ this.$ossUrl }home/business_illustration_active_3.jpg${this.$ossHash}`,
          title: "大数据分析",
          icon: business_icon_3,
          routerLink: '/statistics',
          desc: "9610/9710/9810出口报关申报统计｜1210进口报关申报统计",
        },
      ],
      mouseEnterIndex: 0,
    };
  },
  computed: {
    // isActive() {
    //   return this.isMouseEnter ? 'active' : '';
    // }
  },
  methods: {
    handleMouseEnter: throttle(function (index) {
      this.mouseEnterIndex = index;
    }),
    handleMouseLeave() {
      this.mouseEnterIndex = 0;
    },
    handleOpenPage(url) {
      if (url) {
        window.open(url);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.Business {
  width: 100%;
  // height: 728px;
  margin-top: 100px;
  // background: url('../../assets/images/home/bg_business.jpg') center center no-repeat;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  &.Business:before {
    content: '';
    display: inline-block;
    padding-bottom: 28.125%;
    width: 0.1px;
    vertical-align: middle;
  }
  &-container {
    width: 87.5%;
    overflow: hidden;
    transition: all 0.3s;
    &-item {
      position: relative;
      float: left;
      list-style-type: none;
      width: 14.5%;
      height: 100%;
      transition: all 0.5s;
      &.active {
        width: 56.5%;
        .Business-container-item-container {
          left: 20%;
          top: 30%;
        }
      }
      .img {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
      &-container {
        position: absolute;
        left: 40px;
        bottom: 15px;
        width: 100%;
        color: #fff;
        .title {
          font-size: 30px;
          font-weight: 700;
          color: #fff;
        }
        .icon {
          width: 64px;
          height: 64px;
          margin: 20px 0 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .desc {
          margin-bottom: 5px;
          font-size: 16px;
          color: #fff;
        }
        a{
          font-size: 14px;
          color: #fff;
        }
        .detail_btn {
          font-size: 14px;
          color: #fff;
          cursor: pointer;
          // width: 220px;
          // height: 48px;
          // line-height: 48px;
          // margin-top: 30px;
          // background-color: #3484fb;
          // text-align: center;
          // font-size: 16px;
          // color: #ffffff;
          // cursor: pointer;
        }
      }
    }
  }
  &-control {
    width: 12.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    &-icon {
      width: 64px;
      height: 64px;
      background: url("../../assets/images/home/arrow_right.png") center center
        no-repeat;
      cursor: pointer;
    }
  }

  .Business-container:hover .Business-container-item {
    width: 14.5%;
  }
  .Business-container .Business-container-item:hover {
    width: 56.5%;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1536px) {
  .Business {
    &-container{
      &-item{
        &-container{
          .title {
            font-size: 24px;
          }
          .icon {
            width: 54px;
            height: 54px;
            margin: 15px 0 20px;
          }
          .detail_btn {
            margin-top: 20px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1366px) {
  .Business {
    &-container{
      &-item{
        &-container{
          .title {
            font-size: 18px;
          }
          .icon {
            width: 40px;
            height: 40px;
            margin: 15px 0 20px;
          }
          .desc {
            font-size: 14px;
          }
          .detail_btn {
            width: 160px;
            height: 36px;
            line-height: 36px;
            margin-top: 20px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>